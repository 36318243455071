import TYPES from '@/types';

import { v4 } from 'uuid';

// Application
import GetGoalDefinitionStepQuery
  from '@/modules/flagship/goal-definition-step/application/queries/get-goal-definition-step-query';
import UpdateGoalDefinitionStepCommand
  from '@/modules/flagship/goal-definition-step/application/commands/update-goal-definition-step-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  GoalDefinitionStepEntity,
} from '@/modules/flagship/goal-definition-step/domain/entities/goal-definition-step-entity';
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  ProfileEmergencyFundInvestorGoalEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/ProfileEmergencyFundInvestorGoalEntity';
import {
  ProfileRetirementInvestorGoalEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/ProfileRetirementInvestorGoalEntity';
import {
  ProfileCustomInvestorGoalEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/ProfileCustomInvestorGoalEntity';
import {
  CreateEmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/create-emergency-fund-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipGoalsWizardModalViewModel {
  @Inject(TYPES.GET_GOAL_DEFINITION_STEP_QUERY)
  private readonly get_goal_definition_step_query!: GetGoalDefinitionStepQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.UPDATE_GOAL_DEFINITION_STEP_COMMAND)
  private readonly update_goal_definition_step_cmd!: UpdateGoalDefinitionStepCommand;

  @Inject(TYPES.CREATE_EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
  private readonly emergency_fund_investor_goal_state!: CreateEmergencyFundInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_modal';

  readonly STEPS = [
    {
      step: 0,
      main_item: 'emergency_fund',
      secondary_item: 'calculation',
      component: 'FlagshipGoalsWizardEmergencyFund',
    },
    {
      step: 1,
      main_item: 'emergency_fund',
      secondary_item: 'how_do_you_save',
      component: 'FlagshipGoalsWizardCustomizeFund',
    },
    {
      step: 2,
      main_item: 'custom',
      secondary_item: 'goal_selection',
      component: 'FlagshipGoalsWizardPersonalGoal',
    },
    {
      step: 3,
      main_item: 'custom',
      secondary_item: 'calculation',
      component: 'FlagshipGoalsWizardCustomizeGoal',
    },
    {
      step: 4,
      main_item: 'retirement',
      secondary_item: 'calculation',
      component: 'FlagshipGoalsWizardRetirementFundSecond',
    },
    {
      step: 5,
      main_item: 'retirement',
      secondary_item: 'current_amount',
      component: 'FlagshipGoalsWizardRetirementFund',
    },
    {
      step: 7,
      main_item: 'goal_setting',
      secondary_item: '',
      component: 'FlagshipGoalsWizardAdjustGoals',
    },
    {
      step: 8,
      main_item: '',
      secondary_item: '',
      component: 'FlagshipGoalsWizardProcess',
    },
  ];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  is_loading = false;

  private goal_definition_step_entity: GoalDefinitionStepEntity = {
    main_item: '',
    secondary_item: '',
  }

  investor_goal_state = this.emergency_fund_investor_goal_state.state;

  private readonly view: any;

  constructor(view: any) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.is_loading = true;
    await this.loadGoalDefinitionStep();
    await this.getInvestorProfile();
    this.is_loading = false;
  }

  initializeEmergencyFundState = (
    emergency_fund_investor_goal: ProfileEmergencyFundInvestorGoalEntity,
  ) => {
    this.investor_goal_state.emergency = {
      id: v4(),
      investment_goal_type_id: '',
      months_of_monthly_incomes: emergency_fund_investor_goal?.months_of_monthly_incomes || 0,
      initial_amount: emergency_fund_investor_goal?.initial_amount || 0,
      desired_amount: emergency_fund_investor_goal?.desired_amount || 0,
      monthly_required_amount: emergency_fund_investor_goal?.monthly_required_amount || 0,
      fixed_time_adjusted: emergency_fund_investor_goal?.fixed_time_adjusted || 0,
      accumulated_amount: emergency_fund_investor_goal?.accumulated_amount || 0,
    };
  }

  initializeCustomState = (
    custom_investor_goal: ProfileCustomInvestorGoalEntity,
  ) => {
    this.investor_goal_state.custom = {
      id: v4(),
      investment_goal_type_id: '',
      goal_name: custom_investor_goal?.goal_name || '',
      initial_amount: custom_investor_goal?.initial_amount || 0,
      desired_amount: custom_investor_goal?.desired_amount || 0,
      monthly_required_amount: custom_investor_goal?.monthly_required_amount || 0,
      fixed_time_adjusted: custom_investor_goal?.fixed_time_adjusted || 0,
      accumulated_amount: custom_investor_goal?.accumulated_amount || 0,
      goal_type: custom_investor_goal?.goal_type || {
        id: '',
        name: '',
        label: '',
        description: '',
        icon_name: '',
        created_at: '',
        updated_at: '',
      },
    };
  }

  initializeRetirementFundState = (
    retirement_fund_investor_goal: ProfileRetirementInvestorGoalEntity,
  ) => {
    this.investor_goal_state.retirement = {
      id: v4(),
      investment_goal_type_id: '',
      issued_age: retirement_fund_investor_goal?.issued_age || 0,
      retirement_age_adjusted: retirement_fund_investor_goal?.retirement_age_adjusted || 0,
      retirement_range_adjusted: retirement_fund_investor_goal?.retirement_range_adjusted || '',
      pension_range_adjusted: retirement_fund_investor_goal?.pension_range_adjusted || 0,
      monthly_pension_adjusted: retirement_fund_investor_goal?.monthly_pension_adjusted || '',
      monthly_required_amount: retirement_fund_investor_goal?.monthly_required_amount || 0,
      initial_amount: retirement_fund_investor_goal?.initial_amount || 0,
      initial_amount_adjusted: retirement_fund_investor_goal?.initial_amount_adjusted || 0,
      accumulated_amount: retirement_fund_investor_goal?.accumulated_amount || 0,
    };
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile: InvestorProfileEntity = await this.get_investor_profile_query
        .execute(false);
      if (investor_profile.emergency_fund_investor_goal) {
        const { emergency_fund_investor_goal } = investor_profile;
        if (investor_profile.emergency_fund_investor_goal.accumulated_amount) {
          this.initializeEmergencyFundState(emergency_fund_investor_goal);
        }
      }

      if (investor_profile.custom_investor_goal) {
        const { custom_investor_goal } = investor_profile;
        if (custom_investor_goal.accumulated_amount) {
          this.initializeCustomState(custom_investor_goal);
        }
      }

      if (investor_profile.retirement_investor_goal) {
        const { retirement_investor_goal } = investor_profile;
        if (investor_profile.retirement_investor_goal.accumulated_amount) {
          this.initializeRetirementFundState(retirement_investor_goal);
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  loadGoalDefinitionStep = async () => {
    try {
      const goal_definition_step = await this.get_goal_definition_step_query.execute(false);
      const step_selected = this.STEPS.find(
        (step) => step.main_item === goal_definition_step.main_item
          && step.secondary_item === goal_definition_step.secondary_item,
      );

      if (step_selected) {
        this.current_step = step_selected.step!;
        this.current_component = this.STEPS[this.current_step];
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_goal_definition_step'));
    }
  };

  updateGoalDefinitionStep = async () => {
    try {
      this.is_loading = true;
      this.goal_definition_step_entity.main_item = this.STEPS[this.current_step].main_item;
      // eslint-disable-next-line max-len
      this.goal_definition_step_entity.secondary_item = this.STEPS[this.current_step].secondary_item!;
      await this.update_goal_definition_step_cmd.execute(this.goal_definition_step_entity);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_goal_definition_step'));
    } finally {
      this.is_loading = false;
    }
  };

  nextStep = async () => {
    await this.updateGoalDefinitionStep();

    if (this.current_step < this.STEPS.length - 1) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.view.endProcess();
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess= () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }
}
