



































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import FlagshipGoalsWizardModalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-modal-view-model';

@Component({
  name: 'FlagshipGoalsWizardModal',
  components: {
    FlagshipGoalsWizardEmergencyFund: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardEmergencyFund.vue'),
    FlagshipGoalsWizardCustomizeFund: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardCustomizeFund.vue'),
    FlagshipGoalsWizardPersonalGoal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardPersonalGoal.vue'),
    FlagshipGoalsWizardCustomizeGoal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardCustomizeGoal.vue'),
    FlagshipGoalsWizardRetirementFund: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardRetirementFund.vue'),
    FlagshipGoalsWizardRetirementFundSecond: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardRetirementFundSecond.vue'),
    FlagshipGoalsWizardAdjustGoals: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardAdjustGoals.vue'),
    FlagshipGoalsWizardProcess: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardProcess.vue'),
  },
})
export default class FlagshipGoalsWizardModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_wizard_model = Vue.observable(new FlagshipGoalsWizardModalViewModel(this));

  endProcess() {
    this.synced_is_open = false;
    this.flagship_wizard_model.endProcess();
  }

  mounted() {
    this.flagship_wizard_model.initialize();
  }
}
